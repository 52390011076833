(function ($, window) {
    var ivNotification = function (message, config) {
        this.init(message, config);
        return this;
    };
    ivNotification.prototype = {
        duration: 0,
        waitForUserInteraction: false,
        appendTo: 'body',
        id: null,
        showOverlay: false,
        $overlay: null,
        init: function (message, config) {

            $.extend(this, config);
            var self = this;
            var divClass = 'notification';

            // First remove all notifications
            $("." + divClass).remove();

            if (this.level) {
                divClass += ' ' + this.level;
            }

            if (this.showOverlay) {
                $('body').append('<div class="overlay"></div>');
                this.$overlay = $('body').children().last();
            }
            var uid = Math.floor((Math.random() * 1000) + 1);
            this.id = "notification_" + uid;

            var $message = $('<div class="' + divClass + '" id="' + this.id + '"><span></span></div>');
            if (this.link) {
                var $link = $('<a href="' + this.link + '"></a>');
                $link.append($message);
                $message = $link;
            }
            $message.find('span').append(message);
            $message.hide();

            $(self.appendTo).prepend($message);
            $message.fadeIn('normal');

            if (self.duration > 0) {
                setTimeout(function () {
                    self.remove();
                }, self.duration);
            }
        },
        remove: function () {
            if (this.$overlay) {
                this.$overlay.remove();
                this.$overlay = null;
            }

            $('#' + this.id).fadeOut('normal', function() {
                $('#' + this.id).remove();
            });
        }
    };
    window.IVNotification = ivNotification;
})(jQuery, window);